import { CSSObject } from '@emotion/react';
import { useTheme } from '@cvent/carina/components/ThemeProvider';
import useBreakpoints from '@hooks/useBreakPoints';

export const styles = (): Record<string, CSSObject> => {
  const { spacing, backgroundColor, borderColor, font } = useTheme();
  const { isMobile, isMobileOrTablet } = useBreakpoints();
  return {
    mainContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: spacing[6],
      padding: isMobileOrTablet ? '2rem 1rem' : '2rem',
      justifyContent: 'space-between',
      width: '100%'
    },
    attendanceHeaderContainer: {
      backgroundColor: backgroundColor.interactive.fill.base
    },
    exhibitorHeaderContainer: {
      backgroundColor: backgroundColor.tertiary.fill.base
    },
    attSwitchButtonContainer: {
      button: {
        borderColor: borderColor.neutral.fill.base,
        backgroundColor: backgroundColor.interactive.fill.hover,
        padding: spacing[5],
        marginLeft: spacing[5]
      }
    },
    exhSwitchButtonContainer: {
      button: {
        borderColor: borderColor.neutral.fill.base,
        backgroundColor: backgroundColor.tertiary.fill.hover,
        padding: spacing[5],
        marginLeft: spacing[5]
      }
    },
    attIconColor: {
      button: {
        backgroundColor: backgroundColor.neutral.base,
        padding: spacing[5],
        marginLeft: spacing[5],
        svg: {
          fill: backgroundColor.interactive.fill.focus
        }
      }
    },
    exhIconColor: {
      button: {
        backgroundColor: backgroundColor.neutral.base,
        padding: spacing[5],
        marginLeft: spacing[5],
        svg: {
          fill: backgroundColor.tertiary.fill.focus
        }
      }
    },
    dividerContainer: {
      div: {
        backgroundColor: backgroundColor.base,
        height: '5.75rem',
        margin: spacing[5]
      }
    },
    infoContainer: {
      maxWidth: '37.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: spacing[1],
      h3: {
        color: font.color.fill,
        fontSize: isMobileOrTablet ? font.base.size['2'] : font.base.size['5'],
        fontWeight: font.base.weight.bold,
        textAlign: isMobile ? 'left' : 'right',
        margin: 0
      },
      h4: {
        fontSize: isMobileOrTablet ? font.base.size['2'] : font.base.size['5'],
        fontWeight: font.base.weight.bold,
        color: font.color.fill,
        textAlign: isMobile ? 'left' : 'right',
        margin: 0
      }
    },
    attSwitchButton: {
      backgroundColor: backgroundColor.interactive.fill.surface,
      color: font.color.base,
      '&:active , &:focus': {
        backgroundColor: backgroundColor.interactive.fill.surface,
        color: font.color.base
      }
    },
    exhSwitchButton: {
      backgroundColor: backgroundColor.tertiary.fill.surface,
      color: font.color.base,
      '&:active , &:focus': {
        backgroundColor: backgroundColor.tertiary.fill.surface,
        color: font.color.base
      }
    },
    navMenuContainer: {
      backgroundColor: backgroundColor.base,
      borderBottom: '1px solid lightgray'
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing[2],
      minWidth: '25rem'
    },
    subHeaderContainer: {
      color: font.color.fill,
      fontFamily: font.base.family,
      fontSize: isMobileOrTablet ? font.base.size['7'] : font.base.size['10'],
      fontWeight: font.base.weight.bold
    },
    dropdownContainer: {
      width: '10rem',
      svg: {
        fill: 'white'
      },
      button: {
        border: `2px solid ${borderColor.neutral.fill.base}`
      }
    },
    exhDropdown: {
      backgroundColor: backgroundColor.tertiary.fill.hover,
      border: `1px solid ${borderColor.neutral.fill.base}`,
      color: font.color.fill
    },
    attDropdown: {
      backgroundColor: backgroundColor.interactive.fill.hover,
      border: `1px solid ${borderColor.neutral.fill.base}`,
      color: font.color.fill
    },
    eventInfoContainerS: {
      color: font.color.fill,
      fontFamily: font.base.family,
      fontSize: font.base.size['2'],
      maxWidth: '31.25rem',
      fontWeight: font.base.weight.regular
    },
    attTypeSwitch: {
      button: {
        borderColor: borderColor.interactive.active
      }
    },
    exhTypeSwitch: {
      button: {
        borderColor: borderColor.tertiary.active
      }
    }
  };
};
