import { useNavigation } from '@cvent/carina/components/Navigation';
import { GlobalTopNavigation } from './GlobalTopNavigation';
import { EventContextTopNavigation } from './EventContextTopNavigation';

interface TopNavigationProps {
  overrideContextToggle?: number; // Make the prop optional
}

export function TopNavigation({ overrideContextToggle }: TopNavigationProps): JSX.Element {
  const { logo, page, title, globalNav, localNav } = useNavigation();
  const showLocalNav = localNav && localNav.length > 0;
  const showGlobalNav = globalNav && globalNav.length > 0 && !(localNav && localNav[0]?.items?.length > 0);

  return (
    <>
      {showGlobalNav && <GlobalTopNavigation navigationItems={globalNav} title={title} page={page} logo={logo} />}
      {showLocalNav && (
        <div css={{ height: '20rem' }}>
          <EventContextTopNavigation
            navigationItems={localNav}
            page={page}
            overrideContextToggle={overrideContextToggle}
          />
        </div>
      )}
    </>
  );
}
