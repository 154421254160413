/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import Button from '@cvent/carina/components/Button';
import Modal from '@cvent/carina/components/Modal';
import { ButtonGroup } from '@cvent/carina/components/ButtonGroup';
import { Divider, GlobalNavigationLink } from '@cvent/carina/components/Navigation';
import { OnWhen } from '@cvent/carina/components/OnWhen';
import { SwitchIcon } from '@cvent/carina/components/Icon';

import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useTranslate } from 'nucleus-text';
import { usePagePathParams } from '@tools/usePageQueryParams';
import { useQuery } from '@apollo/client';
import { getFullEventInfoQuery } from '@cvent/rfid-admin-tool-model/operations/event';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import LoadingSpinner from '@cvent/carina/components/LoadingSpinner';
import getConfig from 'next/config';
import { Tabs } from '@cvent/carina/components/Tabs';
import { useTheme } from '@cvent/carina/components/ThemeProvider';
import { Dropdown } from '@cvent/carina/components/Dropdown';
import { styles } from '@components/styles';
import { useState } from 'react';

const LOG = LoggerFactory.create('Event-List');

interface GlobalTopNavigationProps {
  navigationItems: any[];
  page: any;
  overrideContextToggle?: number;
}

export function EventContextTopNavigation({
  navigationItems,
  page,
  overrideContextToggle
}: GlobalTopNavigationProps): JSX.Element {
  // 0 = Attendance | Exhibitor = 1
  let contextToggle = 0;
  let exhibitorEnabled = false;
  const { eventID: eventId } = usePagePathParams();
  const router = useRouter();
  const { translate } = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const { publicRuntimeConfig } = getConfig();

  const modeOptions = ['Attendance', 'Exhibitor'];
  const [selectedMode, setSelectedMode] = useState(undefined);

  if (overrideContextToggle === undefined) {
    if (page.includes('attendance')) {
      contextToggle = 0;
      if (selectedMode === undefined) {
        setSelectedMode('Attendance');
      }
    } else {
      contextToggle = 1;
      if (selectedMode === undefined) {
        setSelectedMode('Exhibitor');
      }
    }
  } else {
    contextToggle = overrideContextToggle;
  }

  const headerString: string = contextToggle ? 'RFID Exhibitor' : 'RFID Attendance';

  const navigationItemsUnpacked = navigationItems[0]?.items;
  const currentNavItem = navigationItemsUnpacked[contextToggle]?.items?.find(item => item.url.href === page)
    ? navigationItemsUnpacked[contextToggle]?.items?.find(item => item.url.href === page)
    : navigationItemsUnpacked[contextToggle]?.items[0];

  const simplifyTitle = (titleInput: string): string => titleInput.replace(/\s/g, '-').toLowerCase();
  const fixTitle = (titleInput: string): string => titleInput.replace(/-/g, ' ').toLowerCase();
  const removeDoubleHyphens = (input: string): string => input.replace(/--+/g, '-');

  const getNavItems = () =>
    navigationItemsUnpacked[contextToggle]?.items?.map(
      (item: { items: unknown; title: string; titleKey: string; url: { href: string } }) => (
        <GlobalNavigationLink key={item.title} testID={removeDoubleHyphens(`local-nav-${simplifyTitle(item.title)}`)}>
          <NextLink href={item.url.href} passHref>
            <a href={item.url.href} onClick={() => setIsOpen(false)}>
              {translate(item.titleKey)}
            </a>
          </NextLink>
        </GlobalNavigationLink>
      )
    );

  const getRedirectSubPath = (contextPath: string): string => {
    if (
      currentNavItem.titleKey === 'page_title_event_locations' ||
      currentNavItem.titleKey === 'page_title_event_clear_location_requests' ||
      currentNavItem.titleKey === 'page_title_event_stations'
    ) {
      return `/events/${eventId}/${contextPath}/${simplifyTitle(translate('page_title_event_monitor'))}`;
    }
    return `/events/${eventId}/${contextPath}/${simplifyTitle(
      translate((currentNavItem as unknown as { titleKey: string }).titleKey)
    )}`;
  };

  const tabOptions = getNavItems().map(({ key }) => ({
    label: key,
    value: key
  }));

  const [selectedTab, setSelectedTab] = useState(undefined);
  if (selectedTab === undefined) {
    const selectedTabValue = fixTitle(page.split('/').pop());
    setSelectedTab(selectedTabValue.charAt(0).toUpperCase() + selectedTabValue.slice(1));
  }

  const {
    loading: loadingFullEvent,
    error: errorFullEvent,
    data: dataFullEvent
  } = useQuery(getFullEventInfoQuery, {
    variables: {
      id: eventId,
      searchEnv: publicRuntimeConfig.ENVIRONMENT === 'dev' ? 'S606' : publicRuntimeConfig.ENVIRONMENT
    },
    notifyOnNetworkStatusChange: true
  });

  if (errorFullEvent) {
    LOG.error('Failed to load event', errorFullEvent);
  } else if (!loadingFullEvent) {
    exhibitorEnabled =
      !!dataFullEvent.fullEventInfo.additionalEventContext.exhibitorAccountId &&
      !!dataFullEvent.fullEventInfo.additionalEventContext.exhibitorEventId;
  }

  const {
    attendanceHeaderContainer,
    exhibitorHeaderContainer,
    attSwitchButtonContainer,
    exhSwitchButtonContainer,
    dividerContainer,
    infoContainer,
    navMenuContainer,
    attDropdown,
    exhDropdown,
    headerContainer,
    subHeaderContainer,
    dropdownContainer,
    mainContainer,
    attSwitchButton,
    exhSwitchButton,
    attTypeSwitch,
    exhTypeSwitch,
    attIconColor,
    exhIconColor
  } = styles();

  return (
    <>
      <div css={!contextToggle ? attendanceHeaderContainer : exhibitorHeaderContainer}>
        <Modal
          aria-label="nav-modal"
          isOpen={isOpen}
          onDismiss={() => setIsOpen(false)}
          css={{ maxWidth: '20rem' }}
          zIndex={100}
        >
          <div style={{ margin: '1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 css={{ marginLeft: '1rem' }}>{translate('navigation_title')}</h2>
              <Button text="Close" onClick={() => setIsOpen(false)} testID="top-navigation-close" />
            </div>
            <ul
              role="menu"
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              {getNavItems()}
            </ul>
          </div>
        </Modal>
        {loadingFullEvent && <LoadingSpinner testID="custom-navigation-spinner" />}
        {!loadingFullEvent && !errorFullEvent && (
          <div css={mainContainer}>
            <div>
              <div css={headerContainer}>
                <div css={subHeaderContainer}>{headerString}</div>
                {exhibitorEnabled && (
                  <div>
                    <OnWhen l xl>
                      <div css={!contextToggle ? attTypeSwitch : exhTypeSwitch}>
                        <ButtonGroup onChange={() => {}} defaultSelected={0} selected={contextToggle}>
                          <ButtonGroup.Item
                            css={!contextToggle ? attSwitchButton : ''}
                            testID="attendance-switch-button"
                            text={translate('button_context_switch_attendance')}
                            onClick={() => {
                              setSelectedMode('Attendance');
                              router.push(getRedirectSubPath('attendance'));
                            }}
                          />
                          <ButtonGroup.Item
                            css={contextToggle ? exhSwitchButton : ''}
                            testID="exhibitor-switch-button"
                            text={translate('button_context_switch_exhibitor')}
                            onClick={() => {
                              setSelectedMode('Exhibitor');
                              router.push(getRedirectSubPath('exhibitor'));
                            }}
                          />
                        </ButtonGroup>
                      </div>
                    </OnWhen>
                    <OnWhen s m>
                      <div css={dropdownContainer}>
                        <Dropdown
                          css={!contextToggle ? attDropdown : exhDropdown}
                          options={modeOptions}
                          selected={selectedMode}
                          onUpdate={updateValue => {
                            setSelectedMode(updateValue);
                            router.push(getRedirectSubPath(updateValue.toLowerCase()));
                          }}
                          portal
                        />
                      </div>
                    </OnWhen>
                  </div>
                )}
              </div>
            </div>
            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div css={infoContainer}>
                <h3>{dataFullEvent.fullEventInfo.eventDetails.title}</h3>
                <h4>{dataFullEvent.fullEventInfo.eventDetails.start.slice(0, 10)}</h4>
              </div>
              <OnWhen l xl>
                <div css={dividerContainer}>
                  <Divider />
                </div>
              </OnWhen>
              <div css={!contextToggle ? attSwitchButtonContainer : exhSwitchButtonContainer}>
                <OnWhen l xl>
                  <Button
                    appearance="filled"
                    text={translate('change_event_button')}
                    size="l"
                    onClick={() => router.push('/events')}
                    testID="event-context-back-button"
                  />
                </OnWhen>
              </div>
              <div css={!contextToggle ? attIconColor : exhIconColor}>
                <OnWhen s m>
                  <Button
                    variant="brand"
                    icon={CustomSwitchIcon}
                    appearance="ghost"
                    size="m"
                    onClick={() => router.push('/events')}
                    testID="event-context-back-shortened-button"
                    isPill
                  />
                </OnWhen>
              </div>
            </div>
          </div>
        )}
      </div>
      <div css={navMenuContainer}>
        <Tabs
          testID="local-nav-"
          removeBottomBorder
          onUpdate={value => {
            setSelectedTab(value);
            router.push(
              `/events/${eventId}/${selectedMode.toLowerCase()}/${
                typeof value === 'string' ? simplifyTitle(value)?.toLowerCase() : value
              }`
            );
          }}
          options={tabOptions}
          scrollLeftAriaLabel="scroll left"
          scrollRightAriaLabel="scroll right"
          selected={selectedTab}
        />
      </div>
    </>
  );
}

function CustomSwitchIcon(contextToggle: number) {
  const { font } = useTheme();
  return <SwitchIcon color={!contextToggle ? font.color.interactive.hover : font.color.tertiary.hover} />;
}
