import { useContext, useMemo } from 'react';
import { BreakpointsContext, BreakpointsContextType } from '@cvent/carina/components/Breakpoints/BreakpointsProvider';

interface BadgeBreakpoints extends BreakpointsContextType {
  isMobile: boolean;
  isDesktop: boolean;
  isMobileOrTablet: boolean;
}
/**
 * Hook for reading up the device screen sizes and 'BreakpointsContext' data
 */
export const useBreakpoints = (): BadgeBreakpoints => {
  const breakpoints = useContext(BreakpointsContext) as BreakpointsContextType;
  return useMemo(
    () => ({
      ...breakpoints,
      isMobile: breakpoints.isDefaultSize || !!breakpoints.isS,
      isDesktop: breakpoints.isM || !!breakpoints.isL,
      isMobileOrTablet: breakpoints.isDefaultSize || breakpoints.isS || !!breakpoints.isM
    }),
    [breakpoints]
  );
};
export default useBreakpoints;
